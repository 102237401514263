<template>
    <div v-html="content">
        {{content}}
    </div>
</template>
<script>
import {mapActions} from 'vuex'
export default {
    name:'PrivacyPolicy',
    data(){
        return{
            content:{}
        }
    },
    methods:{
        ...mapActions('page',['fetchPageDetailsBySlug'])
    },
    mounted(){
        this.fetchPageDetailsBySlug(this.$route.params.slug).then(res=>{
            this.content = res.data
            console.log(res.data)
        })
    }
}
</script>